import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { theme } from "../../utils/global-styles/theme";

const Button = styled(Link)`
  border: 2px solid;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  width: fit-content;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  font-family: ${theme.fontFaces.headers};

  @media only screen and (max-width: ${theme.breakpoints.helper800}) {
    font-size: 12px;
  }
`;

/*
 * See: https://projects.invisionapp.com/share/PVT8FPMGTU5#/screens/376610755
 *
 * Secondary Button
 */
export const SecondaryButton = ({ className, to, children }) => {
  className =
    (className || "") +
    " primary secondary-hover primary-border secondary-border-hover";
  return (
    <Button className={className} to={`${to}/`}>
      {children}
    </Button>
  );
};

SecondaryButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
};

export default SecondaryButton;
