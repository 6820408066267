import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Markdown from "markdown-to-jsx";
import FellowshipAccordion from "../FellowshipAccordion/FellowshipAccordion";
import { theme } from "../../utils/global-styles/theme";
import SearchInput from "./SearchInput";
import TableOfContents from "./TableOfContents";
import SearchResults from "./SearchResults";
const FlexSearch = require("flexsearch/dist/flexsearch.min");
require("flexsearch/lang/en.min.js");

//Create a singleton search index that we load & return with a promise
const getSearchIndex = (() => {
  let indexDirectory = {};

  async function loadSearchIndex(searchIndexPath) {
    let index = indexDirectory[searchIndexPath];
    if (typeof window === "undefined" || index) {
      return index;
    }
    index = new FlexSearch({
      stemmer: "en",
      filter: ["en"],
      profile: "memory",
      // haven't gotten workers to work
      //worker: 2,
      async: true,
    });
    const data = await fetch(searchIndexPath);
    await index.import(await data.text());
    return index;
  }

  return loadSearchIndex;
})();

const Container = styled.div`
    margin-left: 327px;
    margin-right: 327px;

    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin-left: 158px;
      margin-right: 120px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin: 20px 12px 20px 12px;
    }
  `,
  TopicHeader = styled.h3`
    font-size: 35px;
    font-weight: bold;
    letter-spacing: -0.91px;
    line-height: 35px;
    margin-top: 120px;
    margin-bottom: 50px;

    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin-left: 158px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 33px;
      letter-spacing: -0.52px;
      line-height: 40px;
      margin: 50px 35px 20px 0;
    }
  `,
  AccordionsContainer = styled.div`
    margin: 0px 327px 120px 327px;

    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      margin: 0px 285px 120px 0px;
    }

    @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
      margin: 0px 120px 120px 0px;
    }

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      ${theme.margs.mobileLR}
      margin-bottom: 50px;
    }
  `;

const SearchableAccordion = ({
  titleSections,
  searchIndexPath,
  noSearchResults,
}) => {
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    //put inside useEffect so that only a browser calls this
    getSearchIndex(searchIndexPath).then(si => {
      if (si.length !== Object.keys(indexedSections).length) {
        console.warn(
          "Search index has different number of items, likely stale"
        );
      }
      setSearchIndex(si);
    });
  }, [searchIndexPath]);

  //bind search results to search value
  useEffect(() => {
    if (!searchIndex || searchValue.length < 3) {
      setSearchResults(null);
    } else {
      searchIndex.search(
        { query: searchValue, threshold: 0, depth: 3, suggest: true },
        results => {
          setSearchResults([...new Set(results)]);
        }
      );
    }
  }, [searchIndex, searchValue]);

  const updateSearchValue = event => {
    setSearchValue(event.target.value);
    event.preventDefault();
  };
  const indexedSections = titleSections.reduce((map, obj) => {
    return obj.sections.reduce((map, s) => {
      map[s.key] = s;
      return map;
    }, map);
  }, {});

  return (
    <>
      <Container>
        <SearchInput
          key="search-input"
          value={searchValue}
          placeholder="Search the FAQ"
          disabled={!searchIndex}
          onChange={updateSearchValue}
        />
        {searchResults ? null : (
          <TableOfContents key="toc" titleSections={titleSections} />
        )}
      </Container>
      <AccordionsContainer key="content">
        {searchResults ? (
          <SearchResults
            searchResults={searchResults}
            titleSections={titleSections}
            noSearchResults={noSearchResults}
            key="search-results"
          />
        ) : (
          titleSections.map(({ title, id, sections }) => {
            return (
              <div>
                <TopicHeader id={id}>{title}</TopicHeader>
                {sections.map((x, index) => {
                  return (
                    <FellowshipAccordion
                      key={x.key}
                      title={x.title}
                      index={index}
                    >
                      <Markdown
                        options={{
                          overrides: {
                            p: {
                              props: {
                                className: "faq-page-p",
                              },
                            },
                            span: {
                              props: {
                                className: "faq-page-span",
                              },
                            },
                            strong: {
                              props: {
                                className: "faq-page-strong",
                              },
                            },
                            li: {
                              props: {
                                className: "faq-page-li",
                              },
                            },
                            em: {
                              props: {
                                className: "faq-page-italics",
                              },
                            },
                            a: {
                              props: {
                                className: "faq-page-link",
                              },
                            },
                          },
                        }}
                      >
                        {x.description.description}
                      </Markdown>
                    </FellowshipAccordion>
                  );
                })}
              </div>
            );
          })
        )}
      </AccordionsContainer>
    </>
  );
};

SearchableAccordion.propTypes = {
  titleSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      sections: PropTypes.shape({
        key: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ),
  searchIndexPath: PropTypes.string,
  noSearchResults: PropTypes.node,
};

export default SearchableAccordion;
