import React, { useState } from "react";
import PropTypes from "prop-types";
import Styled from "@emotion/styled";
import Markdown from "markdown-to-jsx";
import FellowshipAccordion from "../FellowshipAccordion/FellowshipAccordion";
import { theme } from "../../utils/global-styles/theme";

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: center;
  margin-top: 120px;

  @media only screen and (max-width: ${theme.breakpoints.helper800}) {
    margin-top: 50px;
  }
`,
  Button = Styled.div`
    cursor: pointer;
    font-weight: bold;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  `,
  StaleItem = Styled.div`
    display: none
  `;

const SearchResults = ({
  className,
  titleSections,
  searchResults,
  noSearchResults,
}) => {
  const [viewSize, setViewSize] = useState(0);
  const maxDisplay = (viewSize + 1) * 5;
  className = className || "";

  const indexedSections = titleSections.reduce((map, obj) => {
    return obj.sections.reduce((map, s) => {
      map[s.key] = s;
      return map;
    }, map);
  }, {});

  const viewMore = () => {
    setViewSize(viewSize + 1);
  };

  return (
    <Container className={className}>
      {searchResults.length === 0 ? noSearchResults : null}
      {searchResults.slice(0, maxDisplay).map(key => {
        const x = indexedSections[key];
        if (!x) {
          return <StaleItem key={key}>Not found: {key}</StaleItem>;
        }
        return (
          <FellowshipAccordion
            className="fellowship-faq"
            open={true}
            key={x.key}
            title={x.title}
          >
            <p>
              <Markdown>{x.description.description}</Markdown>
            </p>
          </FellowshipAccordion>
        );
      })}
      {maxDisplay < searchResults.length ? (
        <Button className="primary" key="view-more" onClick={viewMore}>
          View More
        </Button>
      ) : null}
    </Container>
  );
};

SearchResults.propTypes = {
  titleSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      sections: PropTypes.shape({
        key: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ),
  searchResults: PropTypes.arrayOf(PropTypes.number),
  noSearchResults: PropTypes.node,
  className: PropTypes.string,
};

export default SearchResults;
