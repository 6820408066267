import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import SecondaryButton from "../shared/SecondaryButton";

const goToAnchor = key => {
  //scroll to and push history state without causing a refresh
  if (typeof window !== `undefined`) {
    const href = `${window.location.pathname}#${key}`;
    const onClick = event => {
      event.preventDefault();
      document.getElementById(key).scrollIntoView({ behavior: "smooth" });
      window.history.pushState(null, "", href);
    };
    return onClick;
  }
};

const Container = styled.ul`
    margin-top: 20px;
    column-count: 2;
    column-gap: 41px;
    position: relative;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      column-gap: 5px;
      margin-bottom: 75px;
    }
  `,
  ToCCell = styled.li`
    cursor: pointer;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    display: inline-block;
    clear: both;
    float: inline-start;
    color: ${theme.colors.black};
    font-family: ${theme.fontFaces.headers};
    margin-bottom: 24px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    position: relative;
    text-decoration: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 23px;
      height: 1px;
      width: 0;
      background-color: black;
      transition: width 0.2s ease-out;
    }

    &::before {
      left: 50%;
      transform: translateX(-100%);
    }

    &::after {
      right: 50%;
      transform: translateX(100%);
    }

    &:hover::before,
    &:hover::after {
      width: 50%;
    }
  `,
  ContactRow = styled.li`
    display: block;
    clear: both;
    
    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      display: block;
      position: absolute;
      left: 0px;
      bottom: -45px;
    }
  `;

const TableOfContents = ({ titleSections }) => {
  return (
    <Container>
      {titleSections.map(x => {
        return (
          <ToCCell key={x.id} onClick={goToAnchor(x.id)}>
            {x.title}
          </ToCCell>
        );
      })}
      <ContactRow>
        <a className="black-button" href="/contact">
          Contact Us
        </a>
      </ContactRow>
    </Container>
  );
};

TableOfContents.propTypes = {
  titleSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default TableOfContents;
