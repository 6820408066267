import React from "react";
import PropTypes from "prop-types";
import Styled from "@emotion/styled";
import SearchIcon from "../Header/Icons/SearchIcon";
import { theme } from "../../utils/global-styles/theme";

const Container = Styled.div`
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 12px;
`,
  Input = Styled.input`
  border: none;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  font-family: ${theme.fontFaces.headers};
  background-color: ${theme.colors.background};
  font-weight: normal;
  margin-left: 15px;
  margin-top: 0.2em;
  &:focus {
    outline-offset: 0px;
    outline: none;
  }
  &:placeholder {
    font-weight: bold;
  }
`;

const SearchInput = ({ className, ...props }) => {
  className = className || "";
  return (
    <Container className={className}>
      <SearchIcon className="path-black-fill" />
      <Input className="black-placeholder" {...props} />
    </Container>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default SearchInput;
