import React from "react";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import slugify from "slugify";
import SearchableAccordion from "../components/SearchableAccordion/SearchableAccordion";
import { theme } from "../utils/global-styles/theme";

const PageMargins = `
margin-left: 325px;

@media only screen and (max-width: ${theme.breakpoints.helper1350}) {
  margin-left: 158px;
}

@media only screen and (max-width: ${theme.breakpoints.helper800}) {
  margin-left: 12px;
  margin-top: 20px;
}
`,
  PageHeader = styled.h2`
    margin-top: 50px;
    font-size: 53px;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 64px;

    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 36px;
      letter-spacing: -0.94px;
      line-height: 43px;
    }
    ${PageMargins};
  `,
  PageDescription = styled.h3`
    margin-top: 40px;
    ${PageMargins};
  `;

const FAQPage = ({ path, data: { FAQPage } }) => {
  //in an ideal world we would GraphQL query the markdown, instead we mangle FAQ specific data here
  let i = 0;
  const titleSections = FAQPage.contentModules.map(x => {
    const sections = x.description.description
      .split(new RegExp("^#{1,3}", "m"))
      .map((sectionStr, index, arr) => {
        //no headers
        if (arr.length === 1) {
          i += 1;
          return {
            key: i - 1,
            title: x.title,
            description: { description: sectionStr },
          };
        }
        //empty split before first defined title
        if (index === 0 && !sectionStr.trim().length) {
          return null;
        }
        const firstNewlineIndex = sectionStr.indexOf("\n");
        let title, description;

        if (firstNewlineIndex !== -1) {
          title = sectionStr.slice(0, firstNewlineIndex);
          description = sectionStr.slice(firstNewlineIndex + 1);
        } else {
          title = sectionStr;
          description = "";
        }

        i += 1;
        return {
          key: i - 1,
          title: title,
          description: { description },
        };
      })
      .filter(x => !!x);
    return {
      id: slugify(x.title),
      title: x.title,
      sections,
    };
  });

  return (
    <Layout
      isDetailView={false}
      currentPath={path}
      key="faq"
      marketingModal={FAQPage.marketingModal}
    >
      <PageHeader>{FAQPage.heading}</PageHeader>
      <PageDescription>{FAQPage.shortDescription}</PageDescription>
      <SearchableAccordion
        key="main"
        searchIndexPath={`${__PATH_PREFIX__}/faq_search_index.json`}
        titleSections={titleSections}
        noSearchResults={<PageHeader>No matches found.</PageHeader>}
      />
    </Layout>
  );
};

export const query = graphql`
  query FAQPage {
    FAQPage: contentfulPageLayout(queryIdentifier: { eq: "FAQ" }) {
      title
      heading
      shortDescription
      contentModules {
        ... on ContentfulTitleDescription {
          title
          description {
            description
          }
        }
      }
      marketingModal {
        ... on ContentfulMarketingModal {
          id
          cta {
            linkText
            url
          }
          description {
            description
          }
          title
          showAfterPercent
          hubspotListId
          marketingModalImage {
            image {
              fluid {
                aspectRatio
                srcSet
              }
            }
          }
          formFields
          successTitle
          successDescription
          additionalText {
            json
          }
        }
      }
    }
  }
`;

export default FAQPage;
